import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <div className="App">
              <header className="App-header">
                <img src={'/logo512.png'} className="App-logo" alt="logo" />
                <h1>Leadray.io</h1>
                <h2>Lead-generation for software consultancies</h2>
              </header>
            </div>
          } />
          <Route path="/subscribe" element={
            <div className="App">
              <header className="App-header">
                <img src={'/logo512.png'} className="App-logo" alt="logo" />
                <h1>Leadray.io</h1>
                <h2>Thank you for subscribing! 🙏</h2>
              </header>
            </div>
          } />
          <Route path="/unsubscribe" element={
            <div className="App">
              <header className="App-header">
                <img src={'/logo512.png'} className="App-logo" alt="logo" />
                <h1>Leadray.io</h1>
                <h2>You're unsubscribed.<br />We appreciate your feedback 👍</h2>
              </header>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
